import { createClasses } from 'styles';
import Stack from '@mui/material/Stack';
import HeroBanner, { HeroBannerHeadline, HeroBannerButton} from 'components/HeroBanner';
import { gift_items_banner } from 'config/images';
import GiftIcon from '@mui/icons-material/CardGiftcard';
import { appData } from 'config/data.cjs';

const HeroBannerProps = {
	ContainerProps: {
		justifyContent: 'center'
	},
	sx: {
		marginTop: (theme) => theme.config.containerSpacing
	}
};

const useClasses = createClasses((theme) => ({
	heading: {
		...theme.mixins.goldText
	},
	subheading: {
		fontWeight: theme.typography.fontWeightMedium
	},
	button: {
		...theme.mixins.goldButton,
		margin: 0
	}
}), {
	name: 'GiftItemsSection'
});

export default function GiftItemsSection(props) {
	const classes = useClasses(props);

	return (
		<HeroBanner overlay image={gift_items_banner} {...HeroBannerProps}>
			<HeroBannerHeadline
				noTextStroke
				classes={{
					root: classes.heading,
					subheading: classes.subheading
				}}
				title="Auf der Suche nach dem passenden Geschenk?"
				subtitle="Unsere Geschenkartikel für jeden Anlass!"
			/>
			<Stack direction={{sm: 'row'}} gap={{xs: 2, lg: 3}} margin="auto">
				<HeroBannerButton
					className={classes.button}
					color="primary"
					href={appData.pages.giftItems.pathname}
					startIcon={<GiftIcon/>}
				>
					zu den Geschenkartikeln
				</HeroBannerButton>
			</Stack>
		</HeroBanner>
	);
}
