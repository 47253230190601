import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AppContent from 'components/AppContent';
import PageHeadline from 'components/PageHeadline';
import NewsSection from 'components/News/NewsSection';
import Button from 'components/Button';
import HeroBanner from './HomeHeroBanner';
import CardContainer from 'components/CardContainer';
import AssortmentCard from 'components/AssortmentCard';
import RelatedSection from 'components/RelatedSection';
import { useOutletContext } from 'react-router-dom';
import { useDeferredUser } from 'context/user';
import AnniversarySection from 'components/AnniversarySection';
import GiftItemsSection from 'components/GiftItemsSection';
import { appData } from 'config/data.cjs';

const { pages } = appData;

const CardContainerProps = {
	gridMode: true,
	gridTemplateColumns: {
		xs: 'repeat(2, 1fr)',
		md: 'repeat(3, 1fr)',
		desktop: 'repeat(4, 1fr)'
	}
};

export default function HomePage() {
	const { data, sections, cards, related } = useOutletContext();
	const { newsData } = data;

	const [{isRetail}] = useDeferredUser();

	return (
		<AppContent fullWidth>
			<HeroBanner/>
			<PageHeadline title={sections.assortment.title} subtitle={sections.assortment.subtitle}/>
			<CardContainer cards={cards.assortment} CardComponent={AssortmentCard} {...CardContainerProps}/>
			{isRetail && (
				<GiftItemsSection/>
			)}
			<NewsSection
				title={pages.news.title}
				subtitle={pages.news.subtitle}
				cards={newsData.data}
			>
				<Button
					size="large"
					variant="contained"
					sx={{marginX: 'auto', mt: {xs: .5, md: 2}}}
					href={pages.news.pathname}
					startIcon={<NewReleasesIcon/>}
				>
					Alle Neuigkeiten
				</Button>
			</NewsSection>
			<AnniversarySection/>
			{related?.cards && (
				<RelatedSection
					cards={related?.cards}
				/>
			)}
		</AppContent>
	);
}
